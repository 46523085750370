import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { TemplateProject } from '../../templates';
import { ProjectImage, ProjectImageDouble } from '../../components';
export const meta = {
  title: 'CapRaise',
  hint: 'CapRaise platform matching deal providers with investors',
  order: 80,
  type: 'project',
  published: true,
  seoTitle: 'CapRaise',
  seoDescription: 'CapRaise - UX design for fintech marketplace matching deal providers with investors.'
};
export const content = {
  images: {
    hero: {
      src: '/images/capraise/capraise-intro.jpg',
      alt: 'Fintech Investment Marketplace - Capraise',
      density: [1, 2, 4],
      breakpoint: ['s', 'm', 'l']
    },
    bottom: {
      src: '/images/capraise/capraise-end.jpg',
      alt: 'User Interface Design - Capraise',
      density: [1, 2, 4],
      breakpoint: ['s', 'm', 'l']
    },
    thumbnail: {
      src: '/images/capraise/capraise-thumb.jpg',
      alt: 'CapRaise',
      density: [1, 2, 4]
    }
  },
  tags: ['fintech', 'app', 'responsive', 'UX', 'UI', 'marketplace'],
  excerpt: 'CapRaise is a platform that provides family offices with curated investments. It’s essentially a fintech marketplace for investment with size ranging from $20M to over $500M.',
  description: 'CapRaise is a platform that provides family offices with curated investments. It’s essentially a fintech marketplace for investment with size ranging from $20M to over $500M. With headquarters in Australia, CapRaise works globally and has already raised $1.41B capital for its clients. Besides platform design, we also provided CapRaise with a custom investor deck template.',
  problem: 'Family office managers are a demanding audience with no time to spare. On the other hand, deal providers are concerned with privacy, only looking for serious investors. As a result, both sides lose time actively searching for a business partner and often rejecting the vast majority of offers.',
  solution: 'As a first step, we gave deal providers easy access to platform statistics regarding investor criteria, automatically filtering out unwanted deals. Next, we set up the system so family office managers could make profiles to match only certain types of deals. And last, instead of forcing users to search actively, we used automated notifications for matching deals.',
  figures: [{
    value: '$16.8B',
    label: 'Family office capital to be invested'
  }, {
    value: '124',
    label: 'Family offices using the platform'
  }, {
    value: '$100M',
    label: 'Average deal size'
  }, {
    value: '$1.41B',
    label: 'Total capital to be raised'
  }]
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  content,
  _frontmatter
};
const MDXLayout = TemplateProject;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHamwlRCP/EABoQAQEAAgMAAAAAAAAAAAAAAAIBAAMREiL/2gAIAQEAAQUCS6mP1myJCRSc5//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AVf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAEAAgMBAAAAAAAAAAAAAAABABECECFR/9oACAEBAAY/ArgU9L0mOVPsLdf/xAAbEAADAAIDAAAAAAAAAAAAAAAAAREQITFRcf/aAAgBAQABPyGxDcPXgxVN8dDenFGbH//aAAwDAQACAAMAAAAQg9//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhUWFxgf/aAAgBAQABPxAm6C0IbZWotDr2WIMYhU+iINwaF15idbP/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/31d188169aec76efc1e8bc9ab030551b/d2432/capraise-users.webp 314w", "/static/31d188169aec76efc1e8bc9ab030551b/41786/capraise-users.webp 628w", "/static/31d188169aec76efc1e8bc9ab030551b/d4599/capraise-users.webp 1256w", "/static/31d188169aec76efc1e8bc9ab030551b/72bc3/capraise-users.webp 1884w", "/static/31d188169aec76efc1e8bc9ab030551b/51747/capraise-users.webp 2512w", "/static/31d188169aec76efc1e8bc9ab030551b/4e71a/capraise-users.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/31d188169aec76efc1e8bc9ab030551b/7a743/capraise-users.jpg 314w", "/static/31d188169aec76efc1e8bc9ab030551b/6f105/capraise-users.jpg 628w", "/static/31d188169aec76efc1e8bc9ab030551b/10d53/capraise-users.jpg 1256w", "/static/31d188169aec76efc1e8bc9ab030551b/b2e4b/capraise-users.jpg 1884w", "/static/31d188169aec76efc1e8bc9ab030551b/fdcb3/capraise-users.jpg 2512w", "/static/31d188169aec76efc1e8bc9ab030551b/78bd4/capraise-users.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/31d188169aec76efc1e8bc9ab030551b/10d53/capraise-users.jpg",
            "alt": "Target Audience - UX Design | CapRaise",
            "title": "Target Audience - UX Design | CapRaise",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdxVlQf/xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAEFAl//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAVEAEBAAAAAAAAAAAAAAAAAAAgMf/aAAgBAQAGPwKr/8QAGhAAAQUBAAAAAAAAAAAAAAAAAAEQETFRYf/aAAgBAQABPyFU6J1rI1v/2gAMAwEAAgADAAAAEPTf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAAIDAQAAAAAAAAAAAAAAAQAhEBFRQf/aAAgBAQABPxAB2ht7UGeTkqIKyCt1eY//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/161b5e5b32990e0f3bc60eb4697ae43e/d2432/capraise-principles.webp 314w", "/static/161b5e5b32990e0f3bc60eb4697ae43e/41786/capraise-principles.webp 628w", "/static/161b5e5b32990e0f3bc60eb4697ae43e/d4599/capraise-principles.webp 1256w", "/static/161b5e5b32990e0f3bc60eb4697ae43e/72bc3/capraise-principles.webp 1884w", "/static/161b5e5b32990e0f3bc60eb4697ae43e/51747/capraise-principles.webp 2512w", "/static/161b5e5b32990e0f3bc60eb4697ae43e/4e71a/capraise-principles.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/161b5e5b32990e0f3bc60eb4697ae43e/7a743/capraise-principles.jpg 314w", "/static/161b5e5b32990e0f3bc60eb4697ae43e/6f105/capraise-principles.jpg 628w", "/static/161b5e5b32990e0f3bc60eb4697ae43e/10d53/capraise-principles.jpg 1256w", "/static/161b5e5b32990e0f3bc60eb4697ae43e/b2e4b/capraise-principles.jpg 1884w", "/static/161b5e5b32990e0f3bc60eb4697ae43e/fdcb3/capraise-principles.jpg 2512w", "/static/161b5e5b32990e0f3bc60eb4697ae43e/78bd4/capraise-principles.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/161b5e5b32990e0f3bc60eb4697ae43e/10d53/capraise-principles.jpg",
            "alt": "Design Principles - UX Design | CapRaise",
            "title": "Design Principles - UX Design | CapRaise",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/2gAMAwEAAhADEAAAAd4QST//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAEFAl//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwFX/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGBABAQEBAQAAAAAAAAAAAAAAAQAREGH/2gAIAQEAAT8hg93mzhLf/9oADAMBAAIAAwAAABD/AN//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAEBAQEBAQAAAAAAAAAAAAABEQAxcSH/2gAIAQEAAT8QpzmQtXoMwcCR0F55qvxu/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/0c9b6d7ef896c56ba798e1aceb883186/d2432/capraise-timeline.webp 314w", "/static/0c9b6d7ef896c56ba798e1aceb883186/41786/capraise-timeline.webp 628w", "/static/0c9b6d7ef896c56ba798e1aceb883186/d4599/capraise-timeline.webp 1256w", "/static/0c9b6d7ef896c56ba798e1aceb883186/72bc3/capraise-timeline.webp 1884w", "/static/0c9b6d7ef896c56ba798e1aceb883186/51747/capraise-timeline.webp 2512w", "/static/0c9b6d7ef896c56ba798e1aceb883186/4e71a/capraise-timeline.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/0c9b6d7ef896c56ba798e1aceb883186/7a743/capraise-timeline.jpg 314w", "/static/0c9b6d7ef896c56ba798e1aceb883186/6f105/capraise-timeline.jpg 628w", "/static/0c9b6d7ef896c56ba798e1aceb883186/10d53/capraise-timeline.jpg 1256w", "/static/0c9b6d7ef896c56ba798e1aceb883186/b2e4b/capraise-timeline.jpg 1884w", "/static/0c9b6d7ef896c56ba798e1aceb883186/fdcb3/capraise-timeline.jpg 2512w", "/static/0c9b6d7ef896c56ba798e1aceb883186/78bd4/capraise-timeline.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/0c9b6d7ef896c56ba798e1aceb883186/10d53/capraise-timeline.jpg",
            "alt": "Design Workflow and Timelines | CapRaise",
            "title": "Design Workflow and Timelines | CapRaise",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdywlB//xAAWEAEBAQAAAAAAAAAAAAAAAAARABD/2gAIAQEAAQUCnCM//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRAAAwADAAAAAAAAAAAAAAAAAAEREDFh/9oACAEBAAE/Ib1mgmOsYf/aAAwDAQACAAMAAAAQ88//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhQVFhcYH/2gAIAQEAAT8QQLQ+4wmbOHK2kTrea0nnEXXvbgqrBXjP/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/7bb9202a65b968b729e605ac50408cb8/d2432/capraise-flow.webp 314w", "/static/7bb9202a65b968b729e605ac50408cb8/41786/capraise-flow.webp 628w", "/static/7bb9202a65b968b729e605ac50408cb8/d4599/capraise-flow.webp 1256w", "/static/7bb9202a65b968b729e605ac50408cb8/72bc3/capraise-flow.webp 1884w", "/static/7bb9202a65b968b729e605ac50408cb8/51747/capraise-flow.webp 2512w", "/static/7bb9202a65b968b729e605ac50408cb8/4e71a/capraise-flow.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/7bb9202a65b968b729e605ac50408cb8/7a743/capraise-flow.jpg 314w", "/static/7bb9202a65b968b729e605ac50408cb8/6f105/capraise-flow.jpg 628w", "/static/7bb9202a65b968b729e605ac50408cb8/10d53/capraise-flow.jpg 1256w", "/static/7bb9202a65b968b729e605ac50408cb8/b2e4b/capraise-flow.jpg 1884w", "/static/7bb9202a65b968b729e605ac50408cb8/fdcb3/capraise-flow.jpg 2512w", "/static/7bb9202a65b968b729e605ac50408cb8/78bd4/capraise-flow.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/7bb9202a65b968b729e605ac50408cb8/10d53/capraise-flow.jpg",
            "alt": "User Journey - UX Design | CapRaise",
            "title": "User Journey - UX Design | CapRaise",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABUBAQEAAAAAAAAAAAAAAAAAAAED/9oADAMBAAIQAxAAAAGus5KZ0DIZf//EABoQAAIDAQEAAAAAAAAAAAAAAAACAQMREyH/2gAIAQEAAQUCs2Do8ENqlvdhfF//xAAYEQACAwAAAAAAAAAAAAAAAAAAAhESMf/aAAgBAwEBPwFo0sf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAACAQUBAAAAAAAAAAAAAAAAASIQERIzgpH/2gAIAQEABj8Cirmp+idIrHoSP//EABsQAAMBAAMBAAAAAAAAAAAAAAABETEhQVFh/9oACAEBAAE/IXVa/CK9NwfaFR7yUJRJRzRbbUkj/9oADAMBAAIAAwAAABA7/wD/xAAXEQADAQAAAAAAAAAAAAAAAAABEBEh/9oACAEDAQE/EIaKf//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCMf//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESFRMUFhceH/2gAIAQEAAT8QwwnVplukOzg3MU1XTVkosCiVHdamBrvx9hwws9hP/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b62c737b89ef779b5a2067cebe7e2603/d2432/capraise-ui.webp 314w", "/static/b62c737b89ef779b5a2067cebe7e2603/41786/capraise-ui.webp 628w", "/static/b62c737b89ef779b5a2067cebe7e2603/d4599/capraise-ui.webp 1256w", "/static/b62c737b89ef779b5a2067cebe7e2603/72bc3/capraise-ui.webp 1884w", "/static/b62c737b89ef779b5a2067cebe7e2603/51747/capraise-ui.webp 2512w", "/static/b62c737b89ef779b5a2067cebe7e2603/4e71a/capraise-ui.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b62c737b89ef779b5a2067cebe7e2603/7a743/capraise-ui.jpg 314w", "/static/b62c737b89ef779b5a2067cebe7e2603/6f105/capraise-ui.jpg 628w", "/static/b62c737b89ef779b5a2067cebe7e2603/10d53/capraise-ui.jpg 1256w", "/static/b62c737b89ef779b5a2067cebe7e2603/b2e4b/capraise-ui.jpg 1884w", "/static/b62c737b89ef779b5a2067cebe7e2603/fdcb3/capraise-ui.jpg 2512w", "/static/b62c737b89ef779b5a2067cebe7e2603/78bd4/capraise-ui.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b62c737b89ef779b5a2067cebe7e2603/10d53/capraise-ui.jpg",
            "alt": "User Interface Design - UI Design | CapRaise",
            "title": "User Interface Design - UI Design | CapRaise",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAe5KVzqQH//EABkQAAMAAwAAAAAAAAAAAAAAAAABEQISIf/aAAgBAQABBQJuF4NUWOqp/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BV//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAQADAAAAAAAAAAAAAAAAABAAITH/2gAIAQEABj8Cmlv/xAAaEAACAwEBAAAAAAAAAAAAAAABEQAhcRBR/9oACAEBAAE/IYAzBsMC9iqBjZVgVvH/2gAMAwEAAgADAAAAEDQP/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Qo//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAQEBAAIDAAAAAAAAAAAAAAERACFxMVFh/9oACAEBAAE/EIOYfVxOMrLTuZAlPbXErW8hFxPFyZ7OAlSO/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/eb0f72ba29b731886fba043b7d834d2d/d2432/capraise-hifi-1.webp 314w", "/static/eb0f72ba29b731886fba043b7d834d2d/41786/capraise-hifi-1.webp 628w", "/static/eb0f72ba29b731886fba043b7d834d2d/d4599/capraise-hifi-1.webp 1256w", "/static/eb0f72ba29b731886fba043b7d834d2d/72bc3/capraise-hifi-1.webp 1884w", "/static/eb0f72ba29b731886fba043b7d834d2d/51747/capraise-hifi-1.webp 2512w", "/static/eb0f72ba29b731886fba043b7d834d2d/4e71a/capraise-hifi-1.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/eb0f72ba29b731886fba043b7d834d2d/7a743/capraise-hifi-1.jpg 314w", "/static/eb0f72ba29b731886fba043b7d834d2d/6f105/capraise-hifi-1.jpg 628w", "/static/eb0f72ba29b731886fba043b7d834d2d/10d53/capraise-hifi-1.jpg 1256w", "/static/eb0f72ba29b731886fba043b7d834d2d/b2e4b/capraise-hifi-1.jpg 1884w", "/static/eb0f72ba29b731886fba043b7d834d2d/fdcb3/capraise-hifi-1.jpg 2512w", "/static/eb0f72ba29b731886fba043b7d834d2d/78bd4/capraise-hifi-1.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/eb0f72ba29b731886fba043b7d834d2d/10d53/capraise-hifi-1.jpg",
            "alt": "Interactive Prototype - UX Design | CapRaise",
            "title": "Interactive Prototype - UX Design | CapRaise",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAC/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAASUHrHEch//EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMREgT/2gAIAQEAAQUCQM05bZXaa4PQ3bNp/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/AZiP/8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQIBAT8Bp//EABsQAAICAwEAAAAAAAAAAAAAAAERABIQIkFR/9oACAEBAAY/AtQ1FUvBr2W6lCfZ/8QAGxAAAwACAwAAAAAAAAAAAAAAAAERITFBUXH/2gAIAQEAAT8hq15ITV8S6HhxqM8o2qmWJPoDZm1P/9oADAMBAAIAAwAAABDf3//EABcRAAMBAAAAAAAAAAAAAAAAAAABIRH/2gAIAQMBAT8QWdHUP//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QXXIv/8QAHRABAQACAQUAAAAAAAAAAAAAAREAITFBUXGRof/aAAgBAQABPxB06tosuJqkiCsNX7iUQDESI5tII0HDt7wANkBgij08YrQKIOK5/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/bfecdb874683038ad237116ca6c6277c/d2432/capraise-hifi-2.webp 314w", "/static/bfecdb874683038ad237116ca6c6277c/41786/capraise-hifi-2.webp 628w", "/static/bfecdb874683038ad237116ca6c6277c/d4599/capraise-hifi-2.webp 1256w", "/static/bfecdb874683038ad237116ca6c6277c/72bc3/capraise-hifi-2.webp 1884w", "/static/bfecdb874683038ad237116ca6c6277c/51747/capraise-hifi-2.webp 2512w", "/static/bfecdb874683038ad237116ca6c6277c/4e71a/capraise-hifi-2.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/bfecdb874683038ad237116ca6c6277c/7a743/capraise-hifi-2.jpg 314w", "/static/bfecdb874683038ad237116ca6c6277c/6f105/capraise-hifi-2.jpg 628w", "/static/bfecdb874683038ad237116ca6c6277c/10d53/capraise-hifi-2.jpg 1256w", "/static/bfecdb874683038ad237116ca6c6277c/b2e4b/capraise-hifi-2.jpg 1884w", "/static/bfecdb874683038ad237116ca6c6277c/fdcb3/capraise-hifi-2.jpg 2512w", "/static/bfecdb874683038ad237116ca6c6277c/78bd4/capraise-hifi-2.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/bfecdb874683038ad237116ca6c6277c/10d53/capraise-hifi-2.jpg",
            "alt": "Prototyping - UX Design | CapRaise",
            "title": "Prototyping - UX Design | CapRaise",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHupUrNH//EABYQAAMAAAAAAAAAAAAAAAAAAAASIP/aAAgBAQABBQKWP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AVf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhEDH/2gAIAQEAAT8hbVl0V56JQej/2gAMAwEAAgADAAAAEDQP/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QS//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EEf/xAAcEAADAAIDAQAAAAAAAAAAAAAAAREhgTFRYZH/2gAIAQEAAT8QSwKVSOxrRwL6MyKXOyDJK+n/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/7a5bd0badbbaea0960e59f0cce4d0d5f/d2432/capraise-hifi-3.webp 314w", "/static/7a5bd0badbbaea0960e59f0cce4d0d5f/41786/capraise-hifi-3.webp 628w", "/static/7a5bd0badbbaea0960e59f0cce4d0d5f/d4599/capraise-hifi-3.webp 1256w", "/static/7a5bd0badbbaea0960e59f0cce4d0d5f/72bc3/capraise-hifi-3.webp 1884w", "/static/7a5bd0badbbaea0960e59f0cce4d0d5f/51747/capraise-hifi-3.webp 2512w", "/static/7a5bd0badbbaea0960e59f0cce4d0d5f/4e71a/capraise-hifi-3.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/7a5bd0badbbaea0960e59f0cce4d0d5f/7a743/capraise-hifi-3.jpg 314w", "/static/7a5bd0badbbaea0960e59f0cce4d0d5f/6f105/capraise-hifi-3.jpg 628w", "/static/7a5bd0badbbaea0960e59f0cce4d0d5f/10d53/capraise-hifi-3.jpg 1256w", "/static/7a5bd0badbbaea0960e59f0cce4d0d5f/b2e4b/capraise-hifi-3.jpg 1884w", "/static/7a5bd0badbbaea0960e59f0cce4d0d5f/fdcb3/capraise-hifi-3.jpg 2512w", "/static/7a5bd0badbbaea0960e59f0cce4d0d5f/78bd4/capraise-hifi-3.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/7a5bd0badbbaea0960e59f0cce4d0d5f/10d53/capraise-hifi-3.jpg",
            "alt": "Responsive Design - UX Design | CapRaise",
            "title": "Responsive Design - UX Design | CapRaise",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABk6Eu1KBYf//EABkQAQADAQEAAAAAAAAAAAAAAAIBAxESBP/aAAgBAQABBQKCsNI1xyhcTXPpjGul/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHhAAAgICAgMAAAAAAAAAAAAAAREAIQISMUFhcYH/2gAIAQEABj8CB1qCsi+jUSI9xal+DzAsPjqNKf/EAB0QAAICAQUAAAAAAAAAAAAAAAERAEExUWGBofH/2gAIAQEAAT8hKYvcIQs9Fwxy2OUNDDq7EsOMk3CBOKDTc//aAAwDAQACAAMAAAAQtz//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QVtv/xAAdEAACAgIDAQAAAAAAAAAAAAABEQAhYYExUXGx/9oACAEBAAE/EDFKRKE9mtwtBEPoexw8RZ2NJpqCzew+CwNDUHQikknA2Dn7GC6702bn/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/5ace6297c9a33185767d0bc94aa227dd/d2432/capraise-hifi-4.webp 314w", "/static/5ace6297c9a33185767d0bc94aa227dd/41786/capraise-hifi-4.webp 628w", "/static/5ace6297c9a33185767d0bc94aa227dd/d4599/capraise-hifi-4.webp 1256w", "/static/5ace6297c9a33185767d0bc94aa227dd/72bc3/capraise-hifi-4.webp 1884w", "/static/5ace6297c9a33185767d0bc94aa227dd/51747/capraise-hifi-4.webp 2512w", "/static/5ace6297c9a33185767d0bc94aa227dd/4e71a/capraise-hifi-4.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/5ace6297c9a33185767d0bc94aa227dd/7a743/capraise-hifi-4.jpg 314w", "/static/5ace6297c9a33185767d0bc94aa227dd/6f105/capraise-hifi-4.jpg 628w", "/static/5ace6297c9a33185767d0bc94aa227dd/10d53/capraise-hifi-4.jpg 1256w", "/static/5ace6297c9a33185767d0bc94aa227dd/b2e4b/capraise-hifi-4.jpg 1884w", "/static/5ace6297c9a33185767d0bc94aa227dd/fdcb3/capraise-hifi-4.jpg 2512w", "/static/5ace6297c9a33185767d0bc94aa227dd/78bd4/capraise-hifi-4.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/5ace6297c9a33185767d0bc94aa227dd/10d53/capraise-hifi-4.jpg",
            "alt": "High Fidelity Mockups - UX Design | CapRaise",
            "title": "High Fidelity Mockups - UX Design | CapRaise",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAQAF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAd0RGo//xAAYEAACAwAAAAAAAAAAAAAAAAAAARAhMf/aAAgBAQABBQIsUPEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAEhETFB/9oACAEBAAE/IW3sIXaNqp6VhQVpWn//2gAMAwEAAgADAAAAEDMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGRABAAMBAQAAAAAAAAAAAAAAAQARIUFR/9oACAEBAAE/EMARVuuBiYATyIbRgEFT0CaPZbtn/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/9346d2fc3e679c2dc177a5a8624ff05a/d2432/capraise-hifi-5.webp 314w", "/static/9346d2fc3e679c2dc177a5a8624ff05a/41786/capraise-hifi-5.webp 628w", "/static/9346d2fc3e679c2dc177a5a8624ff05a/d4599/capraise-hifi-5.webp 1256w", "/static/9346d2fc3e679c2dc177a5a8624ff05a/72bc3/capraise-hifi-5.webp 1884w", "/static/9346d2fc3e679c2dc177a5a8624ff05a/51747/capraise-hifi-5.webp 2512w", "/static/9346d2fc3e679c2dc177a5a8624ff05a/4e71a/capraise-hifi-5.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/9346d2fc3e679c2dc177a5a8624ff05a/7a743/capraise-hifi-5.jpg 314w", "/static/9346d2fc3e679c2dc177a5a8624ff05a/6f105/capraise-hifi-5.jpg 628w", "/static/9346d2fc3e679c2dc177a5a8624ff05a/10d53/capraise-hifi-5.jpg 1256w", "/static/9346d2fc3e679c2dc177a5a8624ff05a/b2e4b/capraise-hifi-5.jpg 1884w", "/static/9346d2fc3e679c2dc177a5a8624ff05a/fdcb3/capraise-hifi-5.jpg 2512w", "/static/9346d2fc3e679c2dc177a5a8624ff05a/78bd4/capraise-hifi-5.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/9346d2fc3e679c2dc177a5a8624ff05a/10d53/capraise-hifi-5.jpg",
            "alt": "Landing Page Design - UX Design | CapRaise",
            "title": "Landing Page Design - UX Design | CapRaise",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHdS6dKRYf/xAAZEAADAAMAAAAAAAAAAAAAAAABAgMAEBH/2gAIAQEAAQUCxqKDqk2Yr0L/AP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EAB0QAAECBwAAAAAAAAAAAAAAAAEAEQIDEBIhUYH/2gAIAQEABj8CWXq8My3iAJc7X//EABsQAQEAAwADAAAAAAAAAAAAABEBACExEEFR/9oACAEBAAE/IeX3lGNJEn2njbmuiAbkgO6Bz//aAAwDAQACAAMAAAAQC+//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBX/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAQEAAwEBAAAAAAAAAAAAAREAITFRcaH/2gAIAQEAAT8QWrY8vGNkLlQCD9NccnU93Q4kLY21yMScop8N/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/f4f6801a5cd34e4db1c8dad14c17bd21/d2432/capraise-hifi-6.webp 314w", "/static/f4f6801a5cd34e4db1c8dad14c17bd21/41786/capraise-hifi-6.webp 628w", "/static/f4f6801a5cd34e4db1c8dad14c17bd21/d4599/capraise-hifi-6.webp 1256w", "/static/f4f6801a5cd34e4db1c8dad14c17bd21/72bc3/capraise-hifi-6.webp 1884w", "/static/f4f6801a5cd34e4db1c8dad14c17bd21/51747/capraise-hifi-6.webp 2512w", "/static/f4f6801a5cd34e4db1c8dad14c17bd21/4e71a/capraise-hifi-6.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/f4f6801a5cd34e4db1c8dad14c17bd21/7a743/capraise-hifi-6.jpg 314w", "/static/f4f6801a5cd34e4db1c8dad14c17bd21/6f105/capraise-hifi-6.jpg 628w", "/static/f4f6801a5cd34e4db1c8dad14c17bd21/10d53/capraise-hifi-6.jpg 1256w", "/static/f4f6801a5cd34e4db1c8dad14c17bd21/b2e4b/capraise-hifi-6.jpg 1884w", "/static/f4f6801a5cd34e4db1c8dad14c17bd21/fdcb3/capraise-hifi-6.jpg 2512w", "/static/f4f6801a5cd34e4db1c8dad14c17bd21/78bd4/capraise-hifi-6.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/f4f6801a5cd34e4db1c8dad14c17bd21/10d53/capraise-hifi-6.jpg",
            "alt": "Handoff - UX Design | CapRaise",
            "title": "Handoff - UX Design | CapRaise",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      